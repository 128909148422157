<template>
  <div class="contact_us">
    <div class="con_nav">
      <nav-tab :nav-data="navData" />
    </div>
    <div class="con_center">
      <Loan />
    </div>
    <div class="con_bottom">
      <layout-bottom />
    </div>
  </div>
</template>

<script>
import NavTab from '@/components/NavTab'
import LayoutBottom from '@/components/LayoutBottom'
import Loan from '@/components/loan/loanItem'
export default {
  components: {
    NavTab,
    Loan,
    LayoutBottom
  },
  data() {
    return {
      navData: {
        index: 1,
        list: [
          {
            name: 'Volver al inicio',
            link: '/'
          },
          {
            name: 'Sobre nosotros',
            link: '/loan'
          },
          {
            name: 'Preguntas frecuentes',
            link: '/faq'
          }
          // {
          //   name: 'HOW IT WORKS',
          //   link: '/how_it_works'
          // },
          // {
          //   name: 'FAQ',
          //   link: '/faq'
          // },
          // {
          //   name: 'CONTACT US',
          //   link: '/contact_us'
          // }
        ]
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 768px){
  .con_center{
    // padding-top: 60px;
  }
}
@media only screen and (max-width: 767px){}

</style>
